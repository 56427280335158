import React, { Suspense, useEffect, useState, lazy } from "react";
import { CookiesProvider, useCookies } from 'react-cookie'


// Libraries
import { Routes, Route, useLocation } from "react-router-dom";
import retina from "retinajs";
import { AnimatePresence } from "framer-motion";

// Context
import GlobalContext from "./Context/Context";

// Components
import ScrollToTopButton from "./Components/ScrollToTop"

// Home

const CorporatePage = lazy(() => import("./Pages/Home/DS_Corporate"))

const AboutUsPage = lazy(() => import("./Pages/About/DS_Company"))

const CorporateLogin = lazy(() => import("./Pages/DS_Login/DS_CorporateLogin"))
const SandboxLogin = lazy(() => import("./Pages/DS_Login/DS_SandboxLogin"))
const CareerApplication = lazy(() => import("./Pages/DS_Career/DS_CareerApplication"))

// Contact Pages
const ContactUsSimplePage = lazy(() => import("./Pages/Contact/ContactUsSimplePage"))
const ContactUsClassicPage = lazy(() => import("./Pages/Contact/ContactUsClassicPage"))
const ContactUsModernPage = lazy(() => import("./Pages/Contact/ContactUsModernPage"))

const UnderConstruction = lazy(() => import("./Pages/DS_404.jsx"))
const Privacy = lazy(() => import("./Pages/DS_Privacy.jsx"))
const TermsOfService = lazy(() => import("./Pages/DS_TOS.jsx"))

const ModalPopupPage = lazy(() => import("./Pages/ModalPopup"))
const SimpleModel = lazy(() => import("./Pages/ModelPopup/SimpleModel"))
const ContactFormModal = lazy(() => import("./Pages/ModelPopup/ContactFormModal"))
const SubscriptionModal = lazy(() => import("./Pages/ModelPopup/SubscriptionModal"))
const VimeoVideoModal = lazy(() => import("./Pages/ModelPopup/VimeoVideoModal"))
const YouTubeVideoModal = lazy(() => import("./Pages/ModelPopup/YouTubeVideoModal"))
const GoogleMapModal = lazy(() => import("./Pages/ModelPopup/GoogleMapModal"))

// Tempest 
const Home = lazy(() => import("./DS_Tempest/Home"))

// Icon Packs
const IconsMindLinePage = lazy(() => import("./Pages/Icons/IconsMindIconPage"))
const IconsMindSolidPage = lazy(() => import("./Pages/Icons/IconsMindSolidPage"))
const FontAwesomeIconPage = lazy(() => import("./Pages/Icons/FontAwesomeIconPage"))
const FeatherIconPage = lazy(() => import("./Pages/Icons/FeatherIconPage"))
const EtLineIconPage = lazy(() => import("./Pages/Icons/EtLineIconPage"))
const SimplelineIconPage = lazy(() => import("./Pages/Icons/SimplelineIconPage"))
const ThemifyIconPage = lazy(() => import("./Pages/Icons/ThemifyIconPage"))
const AnimationPage = lazy(() => import("./Pages/Animation"))

const Blogs = lazy(() => import("./Pages/Blogs/BlogMasonry"))
const BlogBusinessNetworking = lazy(() => import("./Pages/Articles/DS_Blog_BusinessNetworking"))
const BlogLeadGenerationMarketing = lazy(() => import("./Pages/Articles/DS_Blog_LeadGenerationMarketing"))
const BlogIndustryAnalytics = lazy(() => import("./Pages/Articles/DS_Blog_IndustryAnalytics"))


function App() {
  const [cookies, setCookies] = useCookies(["lang"]);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customModal, setCustomModal] = useState({
    el: null,
    isOpen: false
  })
  const location = useLocation();

  // RetinaJS
  useEffect(() => {
    window.addEventListener('load', retina(document.querySelectorAll('img')));
  }, [])

  useEffect(() => {
    setTimeout(() => {
      import("./Functions/Utilities").then(module => {
        module.SetHeaderMenuPos()
        module.setDocumentFullHeight()
      })
    }, 1000);
  }, [location])

  useEffect(() => {
    if (isModalOpen === true) {
      document.querySelector("body").classList.add("overflow-hidden");
    } else {
      document.querySelector("body").classList.remove("overflow-hidden");
    }
  }, [isModalOpen]);

  // Get the current location and set the window to top
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    setFooterHeight(0);
    setCustomModal({
      ...customModal,
      el: null,
      isOpen: false
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <GlobalContext.Provider
      value={{
        headerHeight,
        setHeaderHeight,
        footerHeight,
        setFooterHeight,
        isModalOpen,
        setIsModalOpen,
        customModal,
        setCustomModal,
      }}
    >
      <div className="App" style={{ "--header-height": `${headerHeight}px` }}>
        {
          <main style={{ marginTop: headerHeight, marginBottom: footerHeight }}>
            <ScrollToTopButton />
            <AnimatePresence exitBeforeEnter>
              <Suspense fallback={<></>}>
                <Routes>
                  <Route path="/" element={<CorporatePage style={{ "--base-color": "#09AEEF" }} />} />
                  <Route path="/company" element={<AboutUsPage style={{ "--base-color": "#09AEEF" }} />} />

                  {/*  */}
                  <Route path="/corporate-login" element={<CorporateLogin style={{ "--base-color": "#0038e3" }} />} />
                  <Route path="/sandbox-login" element={<SandboxLogin style={{ "--base-color": "#0038e3" }} />} />
                  <Route path="/careers" element={<CareerApplication style={{ "--base-color": "#0038e3" }} />} />
                  {/*  */}


                  <Route path="/page/contact-simple" element={<ContactUsSimplePage style={{ "--base-color": "#0038e3" }} />} />
                  <Route path="/page/contact-classic" element={<ContactUsClassicPage style={{ "--base-color": "#0038e3" }} />} />
                  <Route path="/page/contact-modern" element={<ContactUsModernPage style={{ "--base-color": "#0038e3" }} />} />

                  {/* <Route path="/" element={<UnderConstruction style={{ "--base-color": "#0038e3" }} />} /> */}
                  {/* <Route path="/underconstruction" element={<UnderConstruction style={{ "--base-color": "#0038e3" }} />} /> */}
                  <Route path="/privacy" element={<Privacy style={{ "--base-color": "#0038e3" }} />} />
                  <Route path="/terms-of-service" element={<TermsOfService style={{ "--base-color": "#0038e3" }} />} />

                  <Route path="modal-popup" element={<ModalPopupPage />}>
                    <Route path="simple-modal" element={<SimpleModel />} />
                    <Route path="subscription" element={<SubscriptionModal />} />
                    <Route path="contact-form" element={<ContactFormModal />} />
                    <Route path="youtube-video" element={<YouTubeVideoModal />} />
                    <Route path="vimeo-video" element={<VimeoVideoModal />} />
                    <Route path="Google-map" element={<GoogleMapModal />} />
                  </Route>

                  {/* Icon Packs */}
                  <Route path="iconsmindline" element={<IconsMindLinePage />} />
                  <Route path="iconsmindsolid" element={<IconsMindSolidPage />} />
                  <Route path="ETlineicon" element={<EtLineIconPage />} />
                  <Route path="fontawesome" element={<FontAwesomeIconPage />} />
                  <Route path="feather" element={<FeatherIconPage />} />
                  <Route path="simple-line" element={<SimplelineIconPage />} />
                  <Route path="themify" element={<ThemifyIconPage />} />
                  <Route path="animation" element={<AnimationPage />} />

                  <Route path="blogs" element={<Blogs />} />
                  {/* PostLayout */}
                  <Route path="/business-networking" element={<BlogBusinessNetworking style={{ "--base-color": "#09AEEF" }} />} />
                  <Route path="/lead-generation-marketing" element={<BlogLeadGenerationMarketing style={{ "--base-color": "#09AEEF" }} />} />
                  <Route path="/industry-analytics" element={<BlogIndustryAnalytics />} style={{ "--base-color": "#09AEEF" }} />


                  {/*TEMPEST*/ }
                  <Route path="/tempest" element={<Home style={{ "--base-color": "#09AEEF" }} />} />
                  { }

                </Routes>
              </Suspense>
            </AnimatePresence>
          </main>
        }
      </div>
    </GlobalContext.Provider>
  )
}

export default App;